<template>
  <Dialog v-model:visible="videoPlayerDialog">
    <video ref="alarmTableItemModalPlayer"
           autoplay
           class="video-js"
           controls
           cors="anonymous"
           crossorigin="anonymous"
           data-setup='{}'
           preload="false"
           style="width: 800px; height: 600px;"
    >
      <p class="vjs-no-js">To view this video please enable JavaScript</p>
    </video>
  </Dialog>
  <Dialog v-model:visible="pdfDisplayDialog">
    <iframe
        loading="lazy"
        ref="alarmTableItemPDFViewer"
        style="width: 800px; height: 600px;"
    />
  </Dialog>
  <Dialog v-model:visible="imageDisplayDialog">
    <img
        loading="lazy"
        ref="alarmTableItemImageViewer"
        style="width: 800px; height: 600px;"
        alt="image"
    />
  </Dialog>
</template>

<script>

import request from "@/api/request";
import {downloadInstruction} from "@/api/instruction";
import 'video.js/dist/video'
import 'video.js/dist/video-js.css'

export default {
  name: "FileViewer",
  data() {
    return {
      videoPlayerDialog: false,
      pdfDisplayDialog: false,
      imageDisplayDialog: false,
    }
  },
  methods: {
    downloadInstruction(file) {
      let extension = /[^.]+$/.exec(file)[0]
      let src = ''
      let type
      if (extension === 'mp4') {
        this.videoPlayerDialog = true;
      } else if (extension === 'pdf') {
        this.pdfDisplayDialog = true
        type = 'application/pdf'
      } else if (
          extension === 'png'
          || extension === 'jpg'
          || extension === 'jpeg'
          || extension === 'gif'
      ) {
        this.imageDisplayDialog = true
        type = 'image/' + extension
      }

      request({
        url: `/list/instruction-files-content`,
        method: 'get',
        responseType: 'arraybuffer',
        params: {
          filename: file
        }
      }).then((response) => {
        if (extension === 'mp4') {
          type = response.headers['content-type']
        }
        src = window.URL.createObjectURL(new Blob([response.data], {
          type: type,
          encoding: 'ISO-8859-1'
        }))
        if (extension === 'mp4') {
          this.$refs.alarmTableItemModalPlayer.src = src
        } else if (extension === 'pdf') {
          this.$refs.alarmTableItemPDFViewer.src = src
        } else if (extension === 'png'
            || extension === 'jpg'
            || extension === 'jpeg'
            || extension === 'gif') {
          this.$refs.alarmTableItemImageViewer.src = src
        } else {
          downloadInstruction(file)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>
