<template>
  <transition name="slide-fade">
    <Card v-show="id !== null" class="p-col" style="width: 100%;">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0">
              <i :class="`icon_medium p-mr-2 ${selectedAlarmPoint.icon.name}`"
                 :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
              <strong>{{ selectedAlarmPoint.name }}</strong>
            </h3>
          </div>
          <div v-if="hasActions">
            <div v-if="isDeleted">
              <Button v-tooltip.bottom="_t('DELETED_ALARM_RESTORAGE')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-refresh"
                      @click="restoreAlarmPoint"/>
            </div>
            <div v-else>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="cancelAlarmPoint"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check"
                      @click="saveAlarm()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil"
                      @click="isEditable = true"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Clone')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-copy"
                      @click="cloneAlarmPoint()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      @click="deleteAlarmPointDialog = true"/>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <TabView v-model:activeIndex="activeTab">
          <TabPanel :header="_t('menu.general')">
            <div class="p-grid">
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_alarm_id') }}<sup>*</sup></p>
              </div>
              <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                <div v-if="isEditable">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-key"></i>
                    </span>
                    <InputText v-model="selectedAlarmPoint.alarmPointId"
                               :placeholder="_t('label_alarm_id')"
                               class="p-m-0"
                               style="width:100%;"
                               type="text"
                    />
                  </div>
                  <small v-if="validation.alarmPointId" class="p-error">{{ _t('id_required') }}</small>
                </div>
                <p v-else><strong>{{ selectedAlarmPoint.alarmPointId }}</strong></p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_object_name') }}<sup>*</sup></p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <div v-if="isEditable">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                    <InputText v-model="selectedAlarmPoint.name"
                               :placeholder="_t('label_object_name')"
                               class="p-m-0" style="width:100%;"
                    />
                  </div>
                  <small v-if="validation.name" class="p-error">{{ _t('label.name_required') }}</small>
                </div>
                <p v-else><strong>{{ selectedAlarmPoint.name }}</strong></p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <Dropdown v-if="isEditable"
                          v-model="selectedAlarmPoint.confirmPriority"
                          :options="[1,2,3,4,5,6,7,8,9,10]"
                          class="p-m-0"
                          style="width: 100%;"/>
                <p v-else><strong>{{ selectedAlarmPoint.confirmPriority }}</strong></p>
                <small v-if="validation.confirmPriority === null && isEditable"
                       class="p-error">{{ _t('priority_required') }}</small>
              </div>
              <div class="p-col-1 stripedBg p-text-right" style="vertical-align: middle;">
                <Checkbox v-model="selectedAlarmPoint.active"
                          :binary="true"
                          :disabled="!isEditable"
                          class="p-m-0"/>
              </div>
              <div class="p-col-6 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_active_alarm') }}</p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>
                <SelectTextTemplate v-if="isEditable" @handleSave="handleSaveTextTemplate"/>
                <div v-if="isEditable" class="p-inputgroup p-mt-5">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-envelope"></i>
                    </span>
                  <Dropdown v-model="selectedTextVar"
                            :disabled="!isEditable"
                            :options="textVars"
                            :placeholder="_t('select_predefined_variable')"
                            class="p-m-0"
                            style="width: 100%;"
                            @change="copyTextVar"
                  />
                </div>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <div v-if="isEditable">
                  <Textarea
                      v-model="selectedAlarmPoint.text"
                      :placeholder="_t('label_alarm_text')"
                      class="p-m-0"
                      rows="7"
                      style="width:100%"/>
                  <small v-if="validation.text" class="p-error">{{ _t('alarm_text_required') }}</small>
                </div>
                <p v-else><strong>{{ selectedAlarmPoint.text }}</strong></p>
              </div>
              <div class="p-col-12">
                <h3 class="subhead p-mt-4">&nbsp;</h3>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_system_id') }}</p>
              </div>
              <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                  <InputText v-model="selectedAlarmPoint.systemId"
                             :placeholder="_t('label_system_id')"
                             class="p-m-0"
                             style="width:100%;"
                             type="text"
                  />
                </div>
                <p v-else><strong>{{ selectedAlarmPoint.systemId ?? '...' }}</strong></p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_description') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditable"
                            v-model="selectedAlarmPoint.description"
                            :placeholder="_t('label_description')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                <p v-else><strong>{{ selectedAlarmPoint.description ?? '...' }}</strong></p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_organization') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <OrganizationLink ref="orgLink" v-model="selectedAlarmPoint" :editMode="isEditable"
                                  modelType="ALARMPOINT"/>
              </div>
            </div>
          </TabPanel>
          <TabPanel :header="_t('PARTICIPANTS')">
            <div class="p-grid">
              <div class="p-col-3 p-mt-1" style="vertical-align: middle;">
                <div v-if="isEditable">
                  <div v-for="(option, i) in srcOptions" :key="option" class="p-field-radiobutton">
                    <RadioButton :id="'srcOption'+i"
                                 v-model="srcOption"
                                 :disabled="!isEditable"
                                 :value="option.value"
                                 name="srcOption"
                                 @change="syncSrcOptions"
                    />
                    <label :for="'srcOption'+i">{{ _t(option.label) }}</label>
                  </div>
                </div>
                <p v-else>
                  <CustomSelectedOption v-model="srcOption" :options="srcOptions" optionLabel="label"
                                        optionValue="value"/>
                </p>
              </div>
              <div v-if="srcOption=='EXIST'" class="p-col-9" style="vertical-align: middle;">
                <div v-if="isEditable" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-exclamation-triangle"></i>
                    </span>
                  <Dropdown v-model="selectedAlarmPoint.alarmPlanId"
                            :filter="true"
                            :filterPlaceholder="_t('label_alarm_plan')"
                            :options="alarmPlans"
                            class="p-m-0"
                            optionLabel="name"
                            optionValue="id"
                            style="width:100%;"
                            @change="getAlarmPlan($event.value)"
                  />
                  <span class="p-inputgroup-addon">
                      <i v-tooltip.bottom="_t('Clear')"
                         class="pi pi-times"
                         @click="selectedAlarmPlan = {id: null}; selectedAlarmPoint.alarmPlanId = null"></i>
                    </span>
                </div>
                <p v-else>
                  <strong>
                    <Button :label="selectedAlarmPlan.name" class="p-button-link text"
                            @click="toAlarmPlan(selectedAlarmPlan.id)"/>
                  </strong>
                </p>
              </div>
              <div v-if="srcOption=='NEW'" class="p-col-9" style="vertical-align: middle;">
                <div class="p-grid">
                  <div class="p-col-4 stripedBg">
                    <p>{{ _t('label_alarm_plan') }}</p>
                  </div>
                  <div class="p-col-8 stripedBg">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-pencil"></i>
                        </span>
                      <InputText v-model="selectedAlarmPlan.name" :placeholder="_t('label_alarm_plan')" class="p-m-0"
                                 style="width:100%;"/>
                    </div>
                  </div>
                  <div class="p-col-4">
                    <p>{{ _t('label_description') }}</p>
                  </div>
                  <div class="p-col-8">
                    <div class="p-inputgroup p-mt-2">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi pi-pencil"></i>
                        </span>
                      <InputText v-model="selectedAlarmPlan.description" :placeholder="_t('label_description')"
                                 class="p-m-0" style="width:100%;"/>
                    </div>
                  </div>
                  <div class="p-col-4 stripedBg">
                    <p>{{ _t('label_organization') }}</p>
                  </div>
                  <div class="p-col-8 stripedBg">
                    <OrganizationLink v-model="selectedAlarmPlan" :editMode="isEditable"/>
                  </div>
                </div>
              </div>
            </div>
            <h3 class="subhead">{{ _t('label_Target_groups') }}</h3>
            <TabView v-model:activeIndex="activeTabSimple">
              <TabPanel :header="_t('label_Standard_view')">
                <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                     class="p-grid">
                  <Card
                      v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                      :key="callGroup"
                      class="p-mr-2 p-mb-2"
                      style="display:inline-block; width:20rem; font-size: 12px;"
                  >
                    <template #content>
                      <div class="p-grid">
                        <div class="p-col-9">
                          <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                              <span class="p-inputgroup-addon">
                                <i class="pi pi-pencil"></i>
                              </span>
                            <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                       :placeholder="_t('label_pg')" class="p-m-0" style="width:100%;"/>
                          </div>
                          <p v-else class="color-green">
                            <strong>
                              {{
                                selectedAlarmPlan.callGroups[index].name &&
                                selectedAlarmPlan.callGroups[index].name.length ?
                                    selectedAlarmPlan.callGroups[index].name :
                                    '...'
                              }}
                            </strong>
                          </p>
                        </div>
                        <div class="p-col-3 p-text-right">
                          <Button v-if="isEditable && srcOption != 'EXIST'" class="p-button-danger"
                                  @click="initCallGroupDeleteDialog(index)">
                            <i class="pi pi-trash"></i>
                          </Button>
                        </div>
                        <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                          {{ _t('label_notification_method') }}
                        </div>
                        <div
                            :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                          <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                                    v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                    :options="callRules"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width:100%;"
                                    @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                          />
                          <strong v-else>
                            <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                            :default="_t('label_default')"
                                            :options="callRules"
                                            optionLabel="label"
                                            optionValue="value"/>
                          </strong>
                        </div>
                        <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                          <span>{{ _t('label_Number_required_ack') }}</span>
                        </div>
                        <div
                            :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                          <InputText v-if="isEditable && srcOption != 'EXIST'"
                                     v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                     class="p-m-0" min="0"
                                     style="width: 100%;" type="number"
                          />
                          <strong v-else>{{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>
                        </div>
                        <div :class="(isEditable && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                          {{ _t('label_Method_participants_selection') }}
                        </div>
                        <div
                            :class="'p-text-right ' + ((isEditable && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                          <Dropdown v-if="isEditable && srcOption != 'EXIST'"
                                    v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                    :options="selectRules"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width:100%;"
                                    @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                          />
                          <strong v-else>
                            <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                            :default="_t('label_default')"
                                            :options="selectRules"
                                            optionLabel="label"
                                            optionValue="value"/>
                          </strong>
                        </div>
                        <div class="p-col-8" style="vertical-align: middle;">
                          <h3 class="subhead">{{ _t('label_participants') }}</h3>
                        </div>
                        <div class="p-col-4 p-text-right">
                            <span v-if="isEditable && srcOption != 'EXIST'" class="p-buttonset">
                              <Button v-tooltip.bottom="_t('Update')"
                                      class="p-button-success p-button-icon-only p-button-text"
                                      @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                                <i class="pi pi-plus-circle"></i>
                              </Button>
                              <Button
                                  v-tooltip.bottom="_t('Delete')"
                                  :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                                  class="p-button-danger p-button-icon-only p-button-text"
                                  @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                                <i class="pi pi-minus-circle"></i>
                              </Button>
                            </span>
                        </div>
                        <div class="p-col-12">
                          <DataTable
                              v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                              :disabled="!isEditable || srcOption == 'EXIST'" :scrollable="true"
                              :value="selectedAlarmPlan.callGroups[index].members"
                              class="p-m-0 condensed"
                              dataKey="id"
                              scrollHeight="20rem"
                              selectionMode="multiple"
                              style="width:100%; font-size: 12px;"
                              @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                              @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                          >
                            <Column :rowReorder="isEditable && srcOption != 'EXIST'" style="flex: 0 0 2rem"/>
                            <Column field="title"
                            >
                              <template #body="slotProps">
                                <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                <i v-else class="pi pi-users p-mr-1"></i>
                                {{ slotProps.data.title }}
                              </template>
                            </Column>
                            <Column :selectionMode="(isEditable && srcOption != 'EXIST') ? 'multiple' : ''"
                                    style="flex: 0 0 2rem"/>
                          </DataTable>
                        </div>
                      </div>
                    </template>
                  </Card>
                  <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                       style="display: inline-block;">
                    <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                            icon="pi pi-plus"
                            @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                  </div>
                </div>
                <div v-else>
                  <p>{{ _t('label_no_target_groups_found') }}</p>
                  <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                       style="display: inline-block;">
                    <Button v-tooltip="_t('label_add_new')" class="p-button-success p-button-icon"
                            icon="pi pi-plus"
                            @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                  </div>
                </div>
              </TabPanel>
              <TabPanel :header="_t('label_Simplified_view')">
                <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                     class="p-grid">
                  <Card
                      v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                      :key="callGroup.id"
                      class="p-mr-2 p-mb-2"
                      style="display:inline-block; width:20rem; font-size: 12px;"
                  >
                    <template #content>
                      <div class="p-grid">
                        <div class="p-col-9">
                          <div v-if="isEditable && srcOption != 'EXIST'" class="p-inputgroup">
                              <span class="p-inputgroup-addon">
                                <i class="pi pi-pencil"></i>
                              </span>
                            <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                       :placeholder="_t('label_group_name')" class="p-m-0" style="width:100%;"/>
                          </div>
                          <p v-else class="color-green">
                            <strong>{{
                                selectedAlarmPlan.callGroups[index].name &&
                                selectedAlarmPlan.callGroups[index].name.length ?
                                    selectedAlarmPlan.callGroups[index].name :
                                    '[...]'
                              }}
                            </strong>
                          </p>
                        </div>
                        <div class="p-col-3 p-text-right">
                          <Button v-if="isEditable && srcOption != 'EXIST'" class="p-button-danger"
                                  @click="initCallGroupDeleteDialog(index)">
                            <i class="pi pi-trash"></i>
                          </Button>
                        </div>
                        <div v-if="isEditable && srcOption != 'EXIST'" class="p-col-12">
                          <p>
                            <strong>
                              {{ _t('label_select_notification_method') }}
                            </strong>
                          </p>
                          <div v-for="(rule, i) in callRulesSimple"
                               :key="rule.value"
                               :class="['p-field-radiobutton', (rule.value == 'OTHER' ? 'hidden' : '')]">
                            <RadioButton :id="'call_rule'+i"
                                         v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                         :disabled="!isEditable"
                                         :value="rule.value"
                                         name="callRule"
                                         @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                            <label :for="'call_rule'+i">{{ rule.label }}</label>
                          </div>
                        </div>
                        <div v-else class="p-col-12">
                          <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                          :options="callRulesSimple"
                                          optionLabel="label"
                                          optionValue="value"/>
                        </div>
                      </div>
                      <div class="p-grid">

                        <div class="p-col-8" style="vertical-align: middle;">
                          <h3 class="subhead">{{ _t('label_participants') }}</h3>
                        </div>
                        <div class="p-col-4 p-text-right">
                            <span v-if="isEditable && srcOption != 'EXIST'" class="p-buttonset">
                              <Button v-tooltip.bottom="_t('Update')"
                                      class="p-button-success p-button-icon-only p-button-text"
                                      @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                                <i class="pi pi-plus-circle"></i>
                              </Button>
                              <Button
                                  v-tooltip.bottom="_t('Delete')"
                                  :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                                  class="p-button-danger p-button-icon-only p-button-text"
                                  @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                                <i class="pi pi-minus-circle"></i>
                              </Button>
                            </span>
                        </div>
                        <div class="p-col-12">
                          <DataTable
                              v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                              :disabled="!isEditable || srcOption == 'EXIST'" :scrollable="true"
                              :value="selectedAlarmPlan.callGroups[index].members"
                              class="p-m-0 condensed"
                              dataKey="id"
                              scrollHeight="20rem"
                              selectionMode="multiple"
                              style="width:100%; font-size: 12px;"
                              @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                              @row-click="toMember($event.data.participantId, $event.data.participantGroupId)"
                          >
                            <Column :rowReorder="isEditable && srcOption != 'EXIST'" style="flex: 0 0 2rem"/>
                            <Column field="title"
                            >
                              <template #body="slotProps">
                                <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                <i v-else class="pi pi-users p-mr-1"></i>
                                {{ slotProps.data.title }}
                              </template>
                            </Column>
                            <Column :selectionMode="(isEditable && srcOption != 'EXIST') ? 'multiple' : ''"
                                    style="flex: 0 0 2rem"/>
                          </DataTable>
                        </div>
                      </div>
                    </template>
                  </Card>
                  <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                       style="display: inline-block;">
                    <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                            icon="pi pi-plus"
                            @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                  </div>
                </div>
                <div v-else>
                  <p>{{ _t('label_no_target_groups_found') }}</p>
                  <div v-if="isEditable && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                       style="display: inline-block;">
                    <Button v-tooltip="_t('label_Add_new_target_group')" class="p-button-success p-button-icon"
                            icon="pi pi-plus"
                            @click="newCallGroupDialog = true; newCallGroup.name = this._t('label_group') + ' ' + (selectedAlarmPlan.callGroups ? selectedAlarmPlan.callGroups.length + 1 : 1)"/>
                  </div>
                </div>

              </TabPanel>
            </TabView>
          </TabPanel>
          <TabPanel :header="_t('label_instruction_files')">
            <div v-if="selectedAlarmPoint.instructionFiles && selectedAlarmPoint.instructionFiles.length > 0"
                 class="p-grid p-nogutter p-mb-4">
              <div v-for="(instruction, index) in selectedAlarmPoint.instructionFiles"
                   :key="index"
                   class="p-col-12"
              >
                <Button :label="instruction.split('/').pop().replace('_received', '')"
                        class="p-button-link p-button-text p-button-success"
                        @click="$refs.fileViewer.downloadInstruction(instruction)"
                />
                <Button v-if="isEditable"
                        class="p-button-rounded p-button-danger p-button-text"
                        icon="pi pi-times"
                        @click="deleteIntruction(instruction)"
                />
              </div>
            </div>
            <p v-else><b>{{ _t('label_intruction_file_missed') }}</b></p>
            <div v-if="isEditable" class="p-grid">
              <div class="p-col-3 stripedBg">
                <p>
                  {{ _t('select_instruction_file') }}
                </p>
              </div>
              <div class="p-col-5 stripedBg">
                <Dropdown v-if="isEditable"
                          v-model="selectedAlarmPoint.instructionFile"
                          :options="instructions"
                          class="p-m-0"
                          optionLabel="name"
                          optionValue="path"
                          style="width: 100%;"
                >
                  <template #option="slotProps">
                    <div class="p-dropdown-option">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="p-col-4 stripedBg">
                <Button v-if="isEditable"
                        :disabled="!selectedAlarmPoint.instructionFile"
                        :label="_t('label_assign')"
                        class="p-button-success"
                        @click="addInstruction"
                />
                <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
              </div>
            </div>
          </TabPanel>
          <TabPanel :header="_t('label_advanced')">
            <div class="p-grid">
              <div class="p-col-12">
                <h3 class="subhead">{{ _t('label_more_notifications_settings') }}</h3>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_confirm_mode') }}</p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <Dropdown v-if="isEditable"
                          v-model="selectedAlarmPoint.acknowledgeMode"
                          :options="confirmationModes"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width: 100%;"/>
                <p v-else><strong>
                  <SelectedOption v-model="selectedAlarmPoint.acknowledgeMode"
                                  :options="confirmationModes"
                                  optionLabel="label"
                                  optionValue="value"/>
                </strong></p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle; text-align: right">
                <p :class="!isEditable || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                  {{ _t('label_confirm_time') }}</p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <InputText v-if="isEditable"
                           v-model="selectedAlarmPoint.localAcknowledgeTime"
                           :disabled="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA'"
                           class="p-m-0"
                           min="0"
                           style="width:100%;"
                           type="number"
                />
                <p v-else
                   :class="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                  <strong>{{ selectedAlarmPoint.localAcknowledgeTime }}</strong>
                </p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <p :class="!isEditable || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                  {{ _t('label_minutes') }}</p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_media_usage_manual') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <Dropdown v-if="isEditable"
                          v-model="selectedAlarmPoint.mediaUsageType"
                          :options="mediaUsageTypes"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width: 100%;"/>
                <p v-else>
                  <strong>
                    <SelectedOption v-model="selectedAlarmPoint.mediaUsageType"
                                    :options="mediaUsageTypes"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong>
                </p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_media_usage') }}<sup>*</sup></p>
              </div>
              <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                <Dropdown v-if="isEditable"
                          v-model="selectedAlarmPoint.mediaProcessingType"
                          :options="mediaProcessingTypes"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width: 100%;"/>
                <p v-else>
                  <strong>
                    <SelectedOption v-model="selectedAlarmPoint.mediaProcessingType"
                                    :options="mediaProcessingTypes"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong>
                </p>
              </div>
              <div class="p-col-12">
                <h3 class="subhead p-mt-4">{{ _t('label_more_alarm_settings') }}</h3>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_speech_text') }}</p>
                <SpeechText :text="selectedAlarmPoint.speechText"/>
                <Button v-if="isEditable" :label="_t('label_copy_from_alarmtext')"
                        class="p-button p-button-secondary p-mt-4"
                        @click="selectedAlarmPoint.speechText = selectedAlarmPoint.text"/>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditable"
                            v-model="selectedAlarmPoint.speechText"
                            :placeholder="_t('label_speech_text')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                <p v-else><strong>{{ selectedAlarmPoint.speechText }}</strong></p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_rep_mon_interval') }}</p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <Checkbox v-model="repeatableMonitored"
                          :binary="true"
                          :disabled="!isEditable"
                          class="p-m-0"
                          @click="syncRepeatableMonitored"
                />
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <InputText v-if="isEditable"
                           v-model="selectedAlarmPoint.repeatMonInterval"
                           :disabled="!repeatableMonitored"
                           :min="repeatableMonitored ? 1 : 0"
                           class="p-m-0"
                           style="width:100%"
                           type="number"/>
                <p v-else><strong>{{ selectedAlarmPoint.repeatMonInterval }}</strong></p>
              </div>
              <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_minutes') }}</p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_coming') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <Dropdown v-if="isEditable && selectedAlarmPoint.alarmPointId[0] !== '$'"
                          v-model="selectedAlarmPoint.triggering"
                          :options="triggerings"
                          class="p-m-0"
                          optionLabel="label"
                          optionValue="value"
                          style="width: 100%;"/>
                <p v-else>
                  <strong>
                    <SelectedOption v-model="selectedAlarmPoint.triggering"
                                    :options="triggerings"
                                    optionLabel="label"
                                    optionValue="value"/>
                  </strong>
                </p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_number_of_repeats') }}</p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <InputText v-if="isEditable"
                           v-model="selectedAlarmPoint.countRepeat"
                           class="p-m-0"
                           min="0"
                           style="width:100%"
                           type="number"/>
                <p v-else><strong>{{ selectedAlarmPoint.countRepeat }}</strong></p>
              </div>
              <div class="p-col-3 stripedBg" style="vertical-align: middle; text-align: right">
                <p>{{ _t('label_between_repeats') }}</p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <InputText v-if="isEditable"
                           v-model="selectedAlarmPoint.repeatBetween"
                           class="p-m-0"
                           min="0"
                           style="width:100%"
                           type="number"/>
                <p v-else><strong>{{ selectedAlarmPoint.repeatBetween }}</strong></p>
              </div>
              <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                <p>{{ _t('label_minutes') }}</p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_alarm_event_rule') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <Dropdown
                    v-if="isEditable"
                    v-model="selectedAlarmPoint.alarmRuleId"
                    :disabled="!isEditable"
                    :options="alarmRules"
                    :placeholder="_t('label_alarm_event_rule')"
                    class="p-m-0"
                    option-label="name"
                    option-value="id"
                    show-clear
                    style="width: 100%;"
                />
                <router-link v-else :to="'/alarm-rules/'+selectedAlarmPoint.alarmRuleId">
                  <p>
                    {{
                      alarmRules.find(i => i.id === selectedAlarmPoint.alarmRuleId)?.name ?? ''
                    }}
                  </p>
                </router-link>
              </div>
            </div>
          </TabPanel>
          <TabPanel :header="_t('label_Icon')">
            <div v-if="isEditable" class="p-grid">
              <div class="p-col-12">
                <h3 class="subhead">{{ _t('label_Colour') }}</h3>
              </div>
              <div v-for="(color, i) in iconColors" :key="color" class="p-col-1 p-text-center">
                <label :for="`color${i}`">
                  <div :style="`background-color: ${color};`" class="color_sample p-mb-2"/>
                </label>
                <RadioButton :id="`color${i}`" v-model="selectedAlarmPoint.icon.color" :disabled="!isEditable"
                             :value="color"
                             name="color"/>
              </div>
              <div class="p-col-12">
                <h3 class="subhead">{{ _t('label_Icon') }}</h3>
              </div>
              <Card v-for="(section, s) in icons" :key="section.label" class="p-col-3">
                <template #title>
                  <p>{{ section.label }}</p>
                </template>
                <template #content>
                  <div class="p-grid">
                    <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
                      <label :for="`icon_${s}_${i}`">
                        <i :class="`icon p-mb-2 ${icon}`"/>
                      </label><br>
                      <RadioButton :id="`icon_${s}_${i}`" v-model="selectedAlarmPoint.icon.name"
                                   :disabled="!isEditable" :value="icon"
                                   name="icon"/>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
            <div v-else>
              <i :class="`icon_big p-mb-2 ${selectedAlarmPoint.icon.name}`"
                 :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
            </div>
          </TabPanel>
          <TabPanel :header="_t('label_calendar')">
            <div class="p-grid">
              <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
                <div><i class="pi pi-calendar"></i> <strong>{{ _t('label_regular_calendar') }}</strong>
                  <Button
                      v-if="isEditable && getCalendar"
                      :label="_t('label_unassign_schedule')"
                      class="p-button-danger"
                      style="float: right"
                      @click="onUnAssignRotationalCalendar"
                  />
                </div>
                <div v-if="isEditable && !getCalendar && calendarSelectOption === ''">
                  <Button
                      :label="_t('label_assign_existing_schedule')"
                      class="p-button-outlined p-button-success"
                      @click="calendarSelectOption = 'assign'"
                  />
                  <Button
                      :label="_t('label_or')"
                      class="p-button p-button-link"
                      disabled
                      style="color: #000;"
                  />
                  <Button
                      :label="_t('label_create_new_schedule')"
                      class="p-button-outlined p-button-success"
                      @click="calendarSelectOption = 'create'"
                  />

                </div>
              </div>
            </div>
            <CalendarConfigurator v-if="getCalendar" v-model:orgId="orgId"></CalendarConfigurator>
            <template v-else-if="isEditable">
              <div v-if="calendarSelectOption === 'assign'" class="p-grid">
                <div class="p-col-3 p-mt-2">
                  {{ _t('label_assign_existing_schedule') }}
                </div>
                <div class="p-col p-mt-2">
                  <Dropdown v-model="rotationalCalendar" :editable="true"
                            :options="getRotationalCalendars" :placeholder="_t('Select')"
                            optionLabel="name"
                            style="min-width: 400px; width: 100%"
                  />
                </div>
                <div class="p-col-3 p-mt-2">
                  <Button :disabled="!rotationalCalendar.id" class="p-button-secondary"
                          @click="onAssignRotationalCalendar">
                    {{ _t('label_assign') }}
                  </Button>
                </div>
                <div class="p-col-12">
                  <hr>
                </div>
              </div>
              <div v-if="calendarSelectOption === 'create'" class="p-grid">
                <div class="p-col-3">
                  {{ _t('label_create') }}
                </div>
                <div class="p-col p-d-flex">
                  <InputText v-model="rotationalCalendar.name" :placeholder="_t('label_object_name')"
                             class="p-d-inline-flex"
                             style="width: 100%"
                             type="text"/>
                  <InputText v-model="rotationalCalendar.description" :placeholder="_t('label_description')"
                             class="p-d-inline-flex"
                             style="width: 100%" type="text"/>

                  <Calendar v-model="rotationalCalendar.validFrom" class="p-d-inline-flex" dateFormat="dd.mm.yy"
                            style="width: 100%"/>
                </div>
                <div class="p-col-3">
                  <Button
                      :disabled="!rotationalCalendar.name || !rotationalCalendar.description || !rotationalCalendar.validFrom"
                      class="p-button-secondary"
                      @click="onCreateRotationalCalendar">
                    {{ _t('label_create') }}
                  </Button>
                </div>
              </div>
            </template>
            <template v-else><p>{{ _t('label_No_standby_schedule_assigned') }}</p></template>
            <div class="p-grid p-pt-6">
              <div class="p-col-12 stripedBg p-py-2" style="vertical-align: middle;">
                <div class="p-mt-2" style="float: left">
                  <i class="pi pi-calendar"></i>
                  <strong>{{ _t('ALARM_POINT_INDIVIDUAL_CAL') }} </strong>
                </div>
                <Button v-if="isEditable" class="p-button-secondary p-button-rounded" icon="pi pi-plus"
                        style="float: right"
                        @click="newEvent"></Button>
              </div>
            </div>
            <AlarmPointCalendarConfigurator
                v-if="getAlarmPointCalendar"
                v-model:orgId="orgId"
                :alarmPoint="selectedAlarmPoint ?? null"
                @clickEvent="clickEvent"
            >
              <template #title>
                <div></div>
              </template>
            </AlarmPointCalendarConfigurator>
          </TabPanel>
          <TabPanel :header="_t('label_Follow_tab')">
            <FollowUpPage v-if="selectedAlarmPoint"
                          ref="followUp"
                          :initiatorId="selectedAlarmPoint.id"
                          :isEditable="isEditable"
                          :orgId="selectedAlarmPoint.orgId"
            />
          </TabPanel>
          <TabPanel :header="_t('label_history')">
            <ReportsHistoryIndex
                ref="reportHistory"
                :activeTab="30"
                :group-filter="groupFilter"
                :orgFilter="[]"
                :start-immediatly="true"
                filters-prefix="all"
            ></ReportsHistoryIndex>
          </TabPanel>
        </TabView>
      </template>
    </Card>
  </transition>

  <FileViewer ref="fileViewer" />

  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name &&
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ?
            selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}: </strong>{{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Update_participants') }}</h3>
    </template>
    <p>{{ _t('label_Update_participants_for_target_group') }} </p>
    <ParticipantSelector v-model="editCallGroup.members" scrollHeight="40vh" style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_Add_new_target_group') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_target_group_name')" class="p-m-0"
                 style="width: 100%;"/>
    </div>
    <div class="p-mt-2">
      <p>{{ _t('label_organization') }}
        <OrganizationLink v-model="selectedAlarmPlan" :editMode="false"/>
      </p>
      <p>{{ _t('label_alarm_plan') }} <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newEventDialog" :modal="true" :style="{width: '500px'}">
    <template #header>
      <h3>{{ _t('label_alarm_event') }}</h3>
    </template>
    <span class="p-float-label p-mt-5">
      <InputText id="description" v-model="getEvent.title" style="width: 100%" type="text"/>
      <label for="description">{{ _t('label_description') }}</label>
    </span>
    <span class="p-float-label p-mt-5">
      <AutoComplete
          v-model="getEvent.alarmReplacement"
          :dropdown="true"
          :placeholder="_t('label_object_name')"
          :suggestions="filteredAlarmPoints"
          class="p-p-0"
          field="name"
          style="width: 100%"
          @complete="searchAlarmPoint($event)"
      />
    </span>
    <div class="p-grid p-mt-5">
      <div class="p-col-4">
        <span class="p-float-label">
          <Calendar id="startDateSelected" v-model="getEvent.startDateSelected" :showTime="false"
                    dateFormat="dd.mm.yy"/>
          <label for="startDateSelected">{{ _t('label.Start_date') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="startTime" v-model="getEvent.startTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="startTime">{{ _t('label.Start_time') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="endTime" v-model="getEvent.endTime" :disabled="allDay" :showTime="true" :timeOnly="true"/>
          <label for="endTime">{{ _t('label.End_time') }}</label>
        </span>
      </div>
      <div class="p-col-2 p-pt-2">
        <div class="p-field-checkbox p-pt-1">
          <Checkbox id="allday" v-model="allDay" :binary="true"/>
          <label for="allday">{{ _t('label_all_day') }}</label>
        </div>
      </div>
    </div>
    <span class="p-float-label p-mt-3">
      <Checkbox id="active" v-model="getEvent.active" :binary="true"/>
      <label class="p-ml-4" for="active">{{ _t('label_active_alarm') }}</label>
    </span>
    <span class="p-float-label p-mt-3">
      <Checkbox id="repeatType" v-model="getEvent.recurring" :binary="true"/>
      <label class="p-ml-4" for="repeatType">{{ _t('repeat_type') }}</label>
    </span>
    <div v-if="getEvent.recurring">
      <span class="p-float-label p-mt-3">
        <Dropdown
            v-model="getEvent.repeatType"
            :options="getRepeatTypes"
            class="p-p-0"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        >
          <template #option="slotProps">
            {{ _t(slotProps.option.value) }}
          </template>
          <template #value="slotProps">
            {{ _t(slotProps.value) }}
          </template>
        </Dropdown>
      </span>
      <span class="p-float-label p-mt-5">
        <InputNumber id="repeatEvery" v-model="getEvent.repeatEvery" :useGrouping="false" class="p-p-0" mode="decimal"
                     style="width: 100%" type="text"/>
        <label for="repeatEvery">{{ _t('label_repeat_every') }} ({{ repeatEveryTitle }})</label>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.WEEKLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }} </label>
        <div v-for="weekDay in weekDayEnum" :key="weekDay" class="p-d-inline">
          <Checkbox :id="weekDay" v-model="getEvent.markedDays" :name="weekDay" :value="weekDay"/>
          <label :for="weekDay" class="p-pl-1 p-pr-3">{{ weekDay.charAt(0).toUpperCase() }}</label>
        </div>
      </span>
      <span v-if="getEvent.repeatType === repeatTypeEnum.MONTHLY" class="p-d-flex p-mt-4">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <Dropdown
            v-model="getEvent.monthSubType"
            :options="repeatByMonth"
            :placeholder="_t('Select')"
            class="p-p-0"
            optionLabel="label"
            optionValue="value"
            style="width: 100%"
        />
      </span>
      <div class="p-d-flex p-flex-row p-mt-5">
        <div class="p-d-inline-flex p-ai-start p-mt-4 p-pr-4">
          <label class="p-ml-2" for="ends">
            {{ _t('label_ends') }}
          </label>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4">
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsAfter" v-model="getEvent.endType" value="AFTER_TIMES_END"/>
              <label for="endsAfter">{{ _t('label_after') }}</label>
            </span>
          </div>
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsOn" v-model="getEvent.endType" value="ON_DATE"/>
              <label for="endsOn">{{ _t('label_on') }}</label>
            </span>
          </div>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4" style="width: 100%">
          <span class="p-d-inline-flex p-pb-2" style="margin-top: -5px">
            <InputNumber id="countAfterTimesEnd" v-model="getEvent.countAfterTimesEnd"
                         :disabled="getEvent.endType !== 'AFTER_TIMES_END'" :useGrouping="false"
                         class="p-p-0" mode="decimal" style="width: 100%"
                         type="text"/>
            <label class="p-pt-2 p-pl-2" for="countAfterTimesEnd">{{ _t('label_times') }}</label>
          </span>
          <span class="p-d-inline-flex">
            <Calendar id="endDateSelected" v-model="getEvent.endDateSelected" :disabled="getEvent.endType !== 'ON_DATE'"
                      :showTime="false"
                      dateFormat="dd.mm.yy"/>
          </span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button v-if="getIsEditable" :label="_t('label_Remove')" class="p-button-text p-button-danger" icon="pi pi-trash"
              style="float: left" @click="removeSelectedEvent()"/>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times"
              @click="setEvent(null); newEventDialog = false"/>
      <Button :label="_t('Save')" autofocus icon="pi pi-check" @click="createNewEvent()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAlarmPointDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{ selectedAlarmPoint.name }}</strong>: {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteAlarmPointDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteAlarmPoint()"/>
    </template>
  </Dialog>


</template>

<style>
.color_sample {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

.p-tabview-nav {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>

<script>
import CalendarConfigurator from "@/views/standby_schedules/calendar-configurator.vue";
import AlarmPointCalendarConfigurator from "@/views/alarm_point/calendar-configurator.vue";
import OrganizationLink from "@/components/ixarma/OrganizationLink.vue";
import SpeechText from "@/components/speechText.vue";
import ReportsHistoryIndex from "@/views/alarm_table/alarms/index.vue";
import SelectTextTemplate from "@/components/SelectTextTemplate.vue";
import FollowUpPage from "@/views/alarm_point/follow-up/index.vue";
import SelectedOption from "@/components/ixarma/SelectedOption.vue";
import UploadFile from "@/components/UploadFile.vue";
import CustomSelectedOption from "@/components/ixarma/CustomSelectedOption.vue";
import {
  cloneAlarmPoint,
  createAlarmPoint,
  deleteAlarmPoint,
  getAlarmPoint,
  restoreAlarmPoint,
  updateAlarmPoint
} from "@/api/alarmPoint";
import {getAlarmPlan} from "@/api/alarmPlan";
import {mapActions, mapGetters, mapMutations} from "vuex";
import router from "@/router";
import ParticipantSelector from "@/components/ixarma/ParticipantSelector.vue";
import {downloadInstruction} from "@/api/instruction";
import {getInstructionFiles} from "@/api/attachments";
import moment from "moment-timezone";
import {getList} from "@/api/list";
import {RepeatTypeEnum} from "@/enums/repeat-type.enum";
import {WeekDayEnum} from "@/enums/week-day.enum";
import FileViewer from "@/components/FileViewer.vue";

export default {
  name: 'AlarmPointItem',
  components: {
    FileViewer,
    ParticipantSelector,
    CustomSelectedOption,
    UploadFile,
    SelectedOption,
    FollowUpPage,
    SelectTextTemplate,
    ReportsHistoryIndex,
    SpeechText,
    OrganizationLink,
    AlarmPointCalendarConfigurator,
    CalendarConfigurator
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
        id: null,
        icon: {
          name: '',
          color: ''
        }
      })
    },
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'onClearSearchValue',
    'callAlarmPoints',
    'callApplyListFilters',
  ],
  data() {
    return {
      selectedAlarmPoint: {
        id: null,
        icon: {
          name: '',
          color: ''
        }
      },
      isDeleted: false,
      isEditable: false,
      deleteAlarmPointDialog: false,
      alarmPointsGrp: [],
      alarmPoints: [],
      alarmPointsOriginal: [],
      apTotalRecords: 0,
      loading: false,
      currentPage: 0,
      apCurrentPage: 0,
      totalRecords: 0,
      alarmPointRows: 100,
      orgId: localStorage.getItem('orgId') ?? null,
      childFilter: true,
      selectedTab: 'NAME',
      calendarSelectOption: '',
      prefillTextTemplateDialog: false,
      rotationalCalendar: '',
      hoursDuration: 999999999,
      repeatableMonitored: false,
      calledWarning: false,
      srcOption: null,
      selectedAlarmPlan: {
        id: null
      },
      alarmPlans: [],
      validation: {
        name: false,
        alarmPointId: false,
        confirmPriority: 0,
        text: false
      },
      activeTab: 0,
      selectedTextVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      srcOptions: [
        {value: 'EXIST', label: 'label_Selected_plan'},
        {value: 'NEW', label: 'label_Define_new_plan'},
        {value: 'SIMPLE', label: 'label_Simplified_entry'},
      ],
      activeTabSimple: 0,
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      callRules: [],
      selectRules: [],
      originalCallGroup: null,
      editCallGroup: {
        participants: [],
        participantGroups: [],
      },
      editCallGroupDialog: false,
      selectedCallGroupMembers: {},
      newCallGroupDialog: false,
      newCallGroup: {},
      callRulesSimple: [],
      instructions: [],
      confirmationModes: [],
      mediaUsageTypes: [],
      mediaProcessingTypes: [],
      triggerings: [],
      alarmRules: [],
      icons: [],
      iconColors: [
        '#3996f3',
        '#314c5d',
        '#fad165',
        '#85b44a',
        '#8c5cfd',
        '#46c1d3',
        '#e23e57',
        '#989898',
        '#1b499e',
        '#f5856d'
      ],
      newEventDialog: false,
      filteredAlarmPoints: null,
      allDay: false,
      repeatByMonth: [{
        label: 'Day of month',
        value: 'DAY_OF_THE_MONTH',
      }, {
        label: 'Day of week',
        value: 'DAY_OF_THE_WEEK',
      }],
      groupFilter: false,
    }
  },
  mounted() {
    if (this.selectedAlarmPoint.id) {
      this.getAlarmPoint(this.selectedAlarmPoint.id);

      this.getAlarmRules();
      this.getAlarmPlans()
    }
    this.loadInstructions();

    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.mediaProcessingTypes = [
      {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
      {value: 'BY_RANK', label: this._t('BY_RANK')}
    ]
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
  },
  watch: {
    '$route.params.id'(value) {
      if (value) {
        this.getAlarmPoint(value)
      } else {
        this.selectedAlarmPoint.id = null
      }
    },
    rotationalCalendar(value) {
      if (typeof value === 'string') {
        this.callRotationalCalendars({'filterValue': value, 'tableRows': 100});
      }
    },
    calendarSelectOption(value) {
      if (value !== 'assign') {
        this.rotationalCalendar = {
          id: null,
          name: '',
          description: '',
          validFrom: moment().format('DD.MM.YYYY'),
        }
      }
    },
    allDay: function () {
      if (this.allDay) {
        this.getEvent.startTime = '00:00';
        this.getEvent.endTime = '23:59';
      }
    },
    item(value) {
      this.selectedAlarmPoint = value
      if (value.id === 'new') {
        this.isEditable = true;
      }
      this.getAlarmPlan(value.alarmPlanId);
      this.getAlarmPoint(value.id);
    },
    id(value) {
      if (value === 'new') {
        this.isEditable = true;
      }
    },
  },
  methods: {
    ...mapActions({
      'callCalendarById': 'rotationalCalendar/callCalendarById',
      'callAlarmPointCalendar': 'alarmpoints/callCalendar',
      'updateRotaionalCalendar': 'rotationalCalendar/callUpdateCalendar',
      'createRotaionalCalendar': 'rotationalCalendar/callCreateCalendar',
      'callRotationalCalendars': 'rotationalCalendar/callCalendars',
      'callRemoveEvent': 'alarmpoints/callRemoveEvent',
      'callCreateUpdateEvent': 'alarmpoints/callCreateUpdateEvent',
    }),
    ...mapMutations({
      'setCalendar': 'alarmpoints/setCalendar',
      'setEvents': 'alarmpoints/setEvents',
      'setEvent': 'alarmpoints/setEvent',
      'setRotationalIsEditable': 'rotationalCalendar/setIsEditable',
      'setRotationalCalendar': 'rotationalCalendar/setCalendar',
      'setIndividualIsEditable': 'alarmpoints/setIsEditable',
      'createEmptyEvent': 'alarmpoints/createEmptyEvent',
      'setIsEditable': 'alarmpoints/setIsEditable',
      'selectEvent': 'alarmpoints/selectEvent',
      'createEmptyCalendar': 'rotationalCalendar/createEmptyCalendar',
    }),
    restoreAlarmPoint() {
      this.selectedAlarmPoint.icon = JSON.stringify(this.selectedAlarmPoint.icon)
      restoreAlarmPoint(this.selectedAlarmPoint).then(() => {
        this.selectedAlarmPoint = {
          id: null,
          icon: {
            name: '',
            color: ''
          }
        }
        this.$emit('onClearSearchValue')
        this.isDeleted = true
        this.$emit('callAlarmPoints')
      })
    },
    cancelAlarmPoint() {
      this.isEditable = false;
      this.calendarSelectOption = ''
      this.getAlarmPoint(this.selectedAlarmPoint.id);
      this.$refs.followUp.onCancelTrigger()
      this.rotationalCalendar = ''
      this.prefillTextTemplateDialog = false

      if (this.selectedAlarmPoint.id === 'new') {
        this.selectedAlarmPoint = {
          id: null,
          icon: {
            name: '',
            color: ''
          }
        }
        this.isEditable = false
        router.push('/alarm-points')
      }
    },
    getAlarmPoint(id, setIsEditable = false) {
      if (id && id !== 'new') {
        this.loading = true;
        getAlarmPoint(id).then((response) => {
          this.isEditable = setIsEditable
          this.selectedAlarmPoint = response.data
          if (this.selectedAlarmPoint.id && this.selectedAlarmPoint.id !== 'null') {
            this.isDeleted = this.selectedAlarmPoint.deleted
            if (this.$refs.reportHistory) {
              this.$refs.reportHistory.getHistoryByAlarmId(
                  this.selectedAlarmPoint.id,
                  {
                    alarmName: null,
                    alarmPointId: this.selectedAlarmPoint.alarmPointId,
                    statuses: [],
                    systemId: null,
                    triggeredBy: null,
                  },
                  {
                    hours: this.hoursDuration,
                    option: 'HOURS_DURATION'
                  }
              )
            }
          }

          if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval !== 0) {
            this.repeatableMonitored = true
          } else {
            this.repeatableMonitored = false
          }

          this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)

          this.callCalendarById(this.selectedAlarmPoint.rotationalCalendarId)
              .then(() => {
                if (this.getCalendar && !this.calledWarning) {
                  this.$toast.add({
                    severity: 'info',
                    summary: this._t('ALARM_POINT_ROTATIONAL_CAL'),
                    detail: this._t('label_plan_replaced_by_schedule'),
                    life: 5000,
                    closable: true
                  });
                  this.calledWarning = true
                }
              })

          this.callAlarmPointCalendar(this.selectedAlarmPoint.id)
          this.rotationalCalendar = '';
          try {
            this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
          } finally {
            if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
              this.selectedAlarmPoint.icon = {
                color: '#989898',
                name: 'mdi mdi-alert-outline'
              }
            }
          }
          this.srcOption = 'EXIST'
          // this.activeTab = 0
          this.$forceUpdate()
          this.loading = false
        })
      }
    },
    getAlarmPlan(id) {
      if (id) {
        getAlarmPlan(id).then((response) => {
          this.selectedAlarmPlan = response.data
          this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
            if (callGroup.isRandom == true && callGroup.isEscalation == false) {
              callGroup.memberSelectRule = 'RANDOM'
            } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
              callGroup.memberSelectRule = 'ESCALATION'
            } else {
              callGroup.memberSelectRule = 'NONE'
            }
            this.syncGroupMemberCallRule(callGroup)
          }, this)
          this.srcOption = 'EXIST'
        })

      } else {
        this.selectedAlarmPlan = {id: null}
      }
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmPlans = response.data.list
        this.alarmPlans.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    saveAlarm(setIsEditable = false) {
      if (this.validateAlarmPoint() === false) {
        return false
      }

      if (this.srcOption == 'EXIST') {
        this.saveAlarmPoint(setIsEditable)
      } else {
        if (this.selectedAlarmPlan !== null && this.selectedAlarmPlan.callGroups && this.selectedAlarmPlan.callGroups.length > 0) {
          this.saveAlarmPlan(setIsEditable)
        } else {
          this.$root.showMessage('Please, select alarm participants.', 'error')
        }
      }
      this.updateRotaionalCalendar();
      if (this.getMode) {
        this.$refs.followUp.onSaveTrigger();
      } else {
        this.$refs.followUp.onCancelTrigger();
      }
    },
    validateAlarmPoint(isNew = false) {
      if (!this.selectedAlarmPoint.alarmPointId || this.selectedAlarmPoint.alarmPointId.length === 0) {
        this.validation.alarmPointId = true
        return false;
      } else {
        this.validation.alarmPointId = false
      }
      if (!this.selectedAlarmPoint.name || this.selectedAlarmPoint.name.length === 0) {
        this.validation.name = true
        return false;
      } else {
        this.validation.name = false
      }
      if (!isNew) {
        if (!this.selectedAlarmPoint.confirmPriority || this.selectedAlarmPoint.confirmPriority.length === 0) {
          this.validation.confirmPriority = 1
          return false;
        } else {
          this.validation.confirmPriority = 0
        }
        if (!this.selectedAlarmPoint.text || this.selectedAlarmPoint.text.length === 0) {
          this.validation.text = true
          return false;
        } else {
          this.validation.text = false
        }
      }
    },
    saveAlarmPoint(setIsEditable = false) {
      // this.validateAlarmPoint()
      this.selectedAlarmPoint.icon = JSON.stringify(this.selectedAlarmPoint.icon)
      if (!this.selectedAlarmPoint.id || this.selectedAlarmPoint.id == null || this.selectedAlarmPoint.id.startsWith('_') || this.selectedAlarmPoint.id === 'new') {
        delete this.selectedAlarmPoint.id

        createAlarmPoint(this.selectedAlarmPoint).then((response) => {
          this.getAlarmPoint(response.data.id, setIsEditable)
          this.isEditable = setIsEditable
          this.$emit('callApplyListFilters', true)
          this.$emit('callAlarmPoints')
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        this.$refs.orgLink.moveToOrg((increaseVersion) => {
          if (increaseVersion) this.selectedAlarmPoint.version++
          this.selectedAlarmPoint.consequentialAlarms = this.getTriggers
          updateAlarmPoint(this.selectedAlarmPoint).then((response) => {
            this.getAlarmPoint(response.data.id)
            this.isEditable = setIsEditable
            this.$emit('callApplyListFilters', true)
            this.$emit('callAlarmPoints')
          }).catch((error) => {
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
        })
      }
    },
    cloneAlarmPoint() {
      this.selectedAlarmPoint.icon = JSON.stringify(this.selectedAlarmPoint.icon)
      cloneAlarmPoint(this.selectedAlarmPoint).then((response) => {
        this.selectedAlarmPoint = response.data
        this.saveAlarm(true)
      })
    },
    handleSaveTextTemplate(value) {
      this.selectedAlarmPoint.text = value
    },
    copyTextVar() {
      this.selectedAlarmPoint.text = (this.selectedAlarmPoint.text ?? '') + this.selectedTextVar
      this.selectedTextVar = ''
    },
    syncSrcOptions() {
      switch (this.srcOption) {
        case 'EXIST':
          this.activeTabSimple = 0
          break
        case 'NEW':
          this.createAlarmPlan()
          this.activeTabSimple = 0
          break
        case 'SIMPLE':
          this.createAlarmPlan(true)
          this.activeTabSimple = 1
          break
      }
    },
    toAlarmPlan(id) {
      router.push(`/alarm-plans/${id}`)
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'MINIMUM'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'PRECISELY'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    initCallGroupEditDialog(callGroup) {
      this.originalCallGroup = callGroup
      this.editCallGroup.members = Array.from(this.originalCallGroup.members)
      this.editCallGroupDialog = true
    },
    updateCallGroupMembers() {
      this.originalCallGroup.members = Array.from(this.editCallGroup.members)
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }
    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    toMember(participantId, participantGroupId) {
      if (this.isEditable) {
        return
      }

      if (participantId) {
        router.push(`/participants/${participantId}`)
        return
      }

      if (participantGroupId) {
        router.push(`/participant-groups/${participantGroupId}`)

      }
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    downloadInstruction(instructionFile) {
      downloadInstruction(instructionFile)
    },
    deleteIntruction(instruction) {
      this.selectedAlarmPoint.instructionFiles
          .splice(this.selectedAlarmPoint.instructionFiles.findIndex(i => i === instruction), 1)
    },
    addInstruction() {
      if (!this.selectedAlarmPoint.instructionFiles) {
        this.selectedAlarmPoint.instructionFiles = [];
      }
      if (this.selectedAlarmPoint.instructionFiles.indexOf(this.selectedAlarmPoint.instructionFile) === -1) {
        this.selectedAlarmPoint.instructionFiles.push(this.selectedAlarmPoint.instructionFile);
      }
      this.selectedAlarmPoint.instructionFile = null;
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              } else {
                console.log(111)
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    syncRepeatableMonitored() {
      if (this.repeatableMonitored) {
        // this.selectedAlarmPoint.countRepeat = 0
        // this.selectedAlarmPoint.repeatBetween = 0
        this.selectedAlarmPoint.repeatMonInterval = 0
      } else {
        this.selectedAlarmPoint.repeatMonInterval = 1
      }
    },
    getAlarmRules() {
      getList('ALARM_RULE', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmRules = response.data.list
        this.alarmRules.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    onAssignRotationalCalendar() {
      this.selectedAlarmPoint.rotationalCalendarId = this.rotationalCalendar.id ?? null;
      this.saveAlarmPoint();
      this.setIsEditable(false);
    },
    onUnAssignRotationalCalendar() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.rotationalCalendar = ''
          this.selectedAlarmPoint.rotationalCalendarId = null
          this.saveAlarmPoint();
          this.setIsEditable(false);
        },
        reject: () => {
        }
      });
    },
    onCreateRotationalCalendar() {
      this.createEmptyCalendar({orgId: this.orgId});
      this.getCalendar.name = this.rotationalCalendar.name;
      this.getCalendar.description = this.rotationalCalendar.description;
      this.getCalendar.validFrom = this.rotationalCalendar.validFrom;
      this.createRotaionalCalendar()
          .then(() => {
            if (this.getCalendar.id) {
              this.selectedAlarmPoint.rotationalCalendarId = this.getCalendar.id ?? null;
              this.saveAlarmPoint();
              this.setIsEditable(false);
            }
          });
    },
    newEvent() {
      this.createEmptyEvent();
      this.newEventDialog = true;
    },
    searchAlarmPoint(event) {
      let query = event.query;
      let filteredItems = [];

      for (let i = 0; i < this.alarmPoints.length; i++) {
        let item = this.alarmPoints[i];
        if (item.name.toLowerCase().trim().indexOf(query.trim().toLowerCase()) === 0) {
          item.name = item.name.trim();
          filteredItems.push(item);
        }
      }
      this.filteredAlarmPoints = filteredItems;
    },
    removeSelectedEvent() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveEvent({alarmpointId: this.selectedAlarmPoint.id, sourceEventId: this.getEvent.sourceEventId})
              .then(isSuccessful => {
                if (isSuccessful) {
                  this.setEvent(null);
                  this.newEventDialog = false;
                }
              })
              .catch(error => {
                let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                this.$root.showMessage((data.reason ?? 'Error deleting event'), 'error')
              });
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    createNewEvent() {
      const event = this.getEvent.alarmReplacement;

      if (typeof event === "object") {
        this.callCreateUpdateEvent({
          alarmPointId: this.selectedAlarmPoint.id,
          alarmPoint: this.selectedAlarmPoint.alarmReplacement?.trim()
        })
            .then(isSuccessful => {
              if (isSuccessful) {
                this.newEventDialog = false;
                this.$root.showMessage((this._t('SUCCESS')), 'success')
              }
            })
            .catch(error => {
              this.newEventDialog = false;
              let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
              this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
            });
      } else {
        this.newEventDialog = false;
        this.$root.showMessage((this._t('label_no_alarms_found')), 'error')
      }
    },
    clickEvent(event) {
      const eventId = event.target.getAttribute('data-id');
      if (eventId) {
        this.setIsEditable(true);
        this.selectEvent(eventId);
        this.newEventDialog = true;
      }
    },
    deleteAlarmPoint() {
      deleteAlarmPoint(this.selectedAlarmPoint.id).then(() => {
        this.selectedAlarmPoint = {
          id: null,
          icon: {
            color: '',
            name: ''
          }
        }
        this.deleteAlarmPointDialog = false
        this.$emit('callAlarmPoints')
        this.$emit('callApplyListFilters', true)
      }).catch((error) => {
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
  },
  computed: {
    ...mapGetters({
      'getRotationalCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
      'getMode': 'consequentials/getMode',
      'getEvent': 'alarmpoints/getEvent',
      'getRepeatTypes': 'alarmpoints/getRepeatTypes',
      'getIsEditable': 'alarmpoints/getIsEditable',
      'getAlarmPointCalendar': 'alarmpoints/getCalendar',
    }),
    weekDayEnum: () => WeekDayEnum,
    repeatTypeEnum: () => RepeatTypeEnum,
    repeatEveryTitle: function () {
      if (this.getEvent && this.getEvent.repeatType) {
        switch (this.getEvent.repeatType) {
          case RepeatTypeEnum.DAILY:
            return this._t('label_day');
          case RepeatTypeEnum.WEEKDAYS:
          case RepeatTypeEnum.WEEKENDS:
          case RepeatTypeEnum.WEEKLY:
            return this._t('label_week');
          case RepeatTypeEnum.MONTHLY:
            return this._t('label_month');
          case RepeatTypeEnum.YEARLY:
            return this._t('label_year');
        }
      }
      return '';
    },
  }
}
</script>
