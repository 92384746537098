<template>
  <Toast/>
  <div class="p-grid">
    <div v-if="groupFilter" class="p-col-12 p-grid">
      <div class="p-col-3 nowrap">
        <label class="filter-label">{{ _t('label_status') }}</label>
        <MultiSelect
            v-model="alarmFilters.statuses"
            :options="alarmJobStatuses"
            :placeholder="_t('ALARMSTATUS')"
            :showClear="true"
            class="p-inputtext p-p-0 p-ml-2 filter-input"
            display="chip"
            style="width: 160px;"
        >
          <template #value="slotProps">
            <div v-for="option of slotProps.value" :key="option">
              <span>{{ _t(option) }}</span>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ _t('label_status') }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="p-multiselect-car-option">
              <span>{{ _t(slotProps.option) }}</span>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-col-3 nowrap alarm-point-hidden">
        <label class="filter-label">{{ _t('label_object_name') }}</label>
        <InputText v-model="alarmFilters.alarmName" :placeholder="_t('label_search_by_name')"
                   class="p-ml-2 filter-input" style="width: 160px;"/>
      </div>
      <div class="p-col-3 nowrap alarm-point-hidden">
        <label class="filter-label">{{ _t('label_alarm_id') }}</label>
        <InputText v-model="alarmFilters.alarmPointId" :placeholder="_t('label_search_by_alarm_point_ID')"
                   class="p-ml-2 filter-input"
                   style="width: 160px;"/>
      </div>
      <div class="p-col-3 nowrap alarm-point-hidden">
        <label class="filter-label">{{ _t('label_system_id') }}</label>
        <InputText v-model="alarmFilters.systemId" :placeholder="_t('label_search_by_system_ID')"
                   class="p-ml-2 filter-input"
                   style="width: 160px;"/>
      </div>

      <div class="p-col-3 nowrap">
        <label class="filter-label">{{ _t('label_started') }}</label>
        <ExtendedCalendar v-model:date="durationFilters" class="p-ml-2 filter-input" orientation="left" style=""/>
      </div>
      <div class="p-col-3 nowrap">
        <label class="filter-label">{{ _t('label_Last_hours') }}</label>
        <InputNumber v-model="hoursDuration" :disabled="durationFilters.start !== null || durationFilters.end !== null"
                     :min="0"
                     :placeholder="_t('label_last_hours')" class="p-ml-2 filter-input" style="width: 150px!important;"/>
      </div>
      <div class="p-col-3 nowrap">
        <label class="filter-label">{{ _t('filter_triggeredby') }}</label>
        <Dropdown
            v-model="alarmFilters.triggeredBy"
            :options="users"
            :placeholder="_t('filter_triggeredby')"
            :showClear="true"
            class="p-ml-2 filter-input"
            clearIcon="pi pi-times"
            optionLabel="label"
            optionValue="value"
            style="width: 160px;"
        ></Dropdown>
      </div>
      <div class="p-col-3 nowrap">
        <label class="filter-label"></label>
        <Button :label="_t('Clear')" class="p-ml-2 p-button-outlined p-button-danger"
                @click="clearAllFilters()"/>
      </div>
      <div class="p-col-12">
        <label>
          {{ _t('label_participants') }}
        </label>
        <SelectParticipant :active-tab="this.activeTab" :filters-prefix="this.filtersPrefix" :multiple="true"
                           :participants-data="this.participantsByPrefix" class="p-ml-2" @change="setParticipant"/>
      </div>
    </div>
    <transition name="slide-fade">
      <div :class="`style ${selectedJobId ? 'p-md-5 p-lg-5 p-sm-12' : 'p-md-12 p-lg-12 p-sm-12'}`">
        <DataTable
            class="p-datatable-sm"
            v-if="activeTab !== 4"
            v-model:selection="selectedAlarmTableItem"
            :globalFilterFields="['alarmPointName', 'alarmJobStartDate']"
            :lazy="true"
            :loading="loading"
            :page="currentPage"
            :paginator="activeTab !== 4"
            :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink'"
            :rowHover="true"
            :rows="alarmTableRows"
            :rowsPerPageOptions="pageSize"
            :showFilterMatchModes="false"
            :totalRecords="totalRecords"
            :value="alarms"
            responsiveLayout="scroll"
            :scrollHeight="tableHeight"
            selectionMode="single"
            @page="selectTablePage($event)"
            @row-click="triggerGetAlarm($event.data);"
            scrollable
            stripedRows
        >
          <template #paginatorstart>
            <div class="p-inputgroup" style="padding-top:10px;">
              <p class="p-inputgroup-addon" style="padding: 5px; font-size: 1rem;">
                {{ _t('primefaces.paginator.aria.ROWS_PER_PAGE') }}</p>
              <Dropdown v-model="alarmTableRows"
                        :options="pageSize"
                        style="margin: 0px;"
              >
              </Dropdown>
            </div>
          </template>
          <template #paginatorend>
          </template>
          <template #empty>
            {{ _t('label_no_alarms_found') }}
          </template>
          <template #loading>
            {{ _t('label_Loading_alarms_data') }}
          </template>
          <span v-if="selectedJobId === null">
            <Column :header="_t('label_status')"
                    :hidden="!handleCheckRow('status')"
                    field="alarmJobStatus"
            >
                <template #filter>
                  <MultiSelect
                      v-model="alarmFilters.statuses"
                      :options="alarmJobStatuses"
                      :placeholder="_t('label_status')"
                      :showClear="true"
                      display="chip"
                      @select="filterCallback()"
                  >
                  </MultiSelect>
                </template>
                <template #body="{data}">
                  <div class="p-d-flex">
                    <div class="p-d-inline-flex " style="width:1rem;">
                      <Button :style="'right:20px; border: none; border-radius: 5px; margin:2px; display: inline-block; background-color: ' +
                          associateColor(data.jobStatusColor)"
                              type="button"
                      />
                    </div>
                    <div class="p-d-inline-flex p-mt-3">
                      <strong style="max-width: 130px; word-break: break-word;">
                        {{ _t(data.alarmJobStatus) }}
                      </strong>
                    </div>
                  </div>
                </template>
              </Column>
            <Column :hidden="!handleCheckRow('status')"
                    field="alarmPointStatus"
            >
                <template #body="{data}">
                  <div class="p-d-inline-flex p-mt-2">
                    <p
                        v-tooltip.bottom="_t(data.alarmPointStatus)"
                        style="display:inline-block; vertical-align: center; width: auto; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    >
                      {{ _t(data.alarmPointStatus) }}
                    </p>
                  </div>
                </template>
              </Column>
            <Column
                :header="_t('label_Alarm_name')"
                :hidden="!handleCheckRow('alarmName')"
                field="alarmPointName"
                style="max-width: 30rem;word-break: break-word;"
            >
                 <template #filter="{}">
                    <InputText
                        v-model="alarmFilters.alarmName"
                        :placeholder="_t('label_search_by_name')"
                    />
                </template>
                <template #body="{data}">
                  <div class="p-d-flex" style="flex-wrap: wrap; align-items: center;">
                    <div class="p-d-inline-flex">
                      <Button
                          :style="`border: none; border-radius: 5px; margin:5px 5px 5px 0; display: inline-block; background-color: ${data &&  data.icon && data.icon.color ? data.icon.color : '#989898'}`"
                          class="no-transition"
                          type="button"
                          @click="selectedAlarmTableItem = data; triggerGetAlarm(data.alarmJobId);"
                      >
                        <span>
                      <i
                          :class="data && data.icon && data.icon.name ? data.icon.name : 'mdi mdi-alert-outline'"
                          style="font-size: 16px !important;"
                      />
                        </span>
                      </Button>
                    </div>
                    <div>
                        {{ data.alarmPointName }}
                    </div>
                  </div>
                </template>
              </Column>
            <Column
                :header="_t('filter_triggeredby')"
                :hidden="!handleCheckRow('triggeredBy')"
                field="triggeredBy"
                style="max-width: 30rem;word-break: break-word;"
            >
              <template #filter="{}">
                  <InputText v-model="alarmFilters.triggeredBy" :placeholder="_t('filter_triggeredby')"
                             class="p-column-filter" @keydown.enter="filterCallback()"/>
              </template>
                <template #body="{data}">
                    <div class="p-d-flex" style="flex-wrap: wrap;">
                    <div class="p-d-inline-flex">
                      <p>
                      <strong>
                        {{ data.triggeredBy }}
                      </strong>
                      </p>
                    </div>
                    </div>
                </template>
            </Column>
            <Column
                :header="_t('label_alarm_id')"
                :hidden="!handleCheckRow('alarmId')"
                field="alarmPointId"
                style="max-width: 30rem;word-break: break-word;"
            >
              <template #filter="{}">
                  <InputText v-model="alarmFilters.alarmPointId" :placeholder="_t('label_search_by_alarm_point_ID')"
                             class="p-column-filter " @keydown.enter="filterCallback()"/>
              </template>
              <template #body="{data}">
                <p v-tooltip.bottom="data.alarmPointId">{{ data.alarmPointId }}</p>
              </template>
            </Column>
            <Column
                :header="_t('label_started')"
                :hidden="!handleCheckRow('started')"
                field="alarmJobStartDate" filterField="alarmJobStartDate"
                filterMatchMode="contains">
                  <template #filter="{}" style="width: 100px;">
                      <ExtendedCalendar
                          v-model:date="durationFilters"
                          orientation="left"
                      />
                  </template>
            </Column>
            <Column
                :header="_t('label_ended')"
                :hidden="!handleCheckRow('going')"
                field="alarmJobEndDate" filterField="alarmJobEndDate" filterMatchMode="contains"
            >
                  <template #filter="{}" style="width: 100px;">
                    <div class="p-inputgroup">
                      <p class="p-inputgroup-addon p-mb-0">Last hours</p>
                      <InputNumber v-model="hoursDuration" placeholder="Last hours..." style="height: 2rem;"/>
                    </div>
                  </template>
              <template #body="{data}">
                <img v-if="!data.alarmJobEndDate" src="assets/layout/images/flag.png"
                     style="width: 1.5rem"/>
                <p v-else>{{ data.alarmJobEndDate }}</p>
              </template>
            </Column>
            <Column
                :header="_t('label_system_id')"
                :hidden="!handleCheckRow('alarmId')"
                field="alarmPointSysId"
            >
            </Column>
          </span>
          <span v-else>
                <Column field="alarmPointName" style="min-width:12rem">
                <template #filter="{}">
                  <div class="p-grid">
                    <Dropdown
                        v-model="alarmFilters.statuses"
                        :options="alarmJobStatuses"
                        class="custom-single-view-filter"
                    >
                  </Dropdown>
                  <InputText
                      v-model="alarmFilters.alarmName"
                      :placeholder="_t('label_search_by_name')"
                      class="custom-single-view-filter"/>
                  <InputText
                      v-model="alarmFilters.triggeredBy"
                      :placeholder="_t('filter_triggeredby')"
                      class="custom-single-view-filter" @keydown.enter="filterCallback()"/>
                  <InputText
                      v-model="alarmFilters.alarmPointId"
                      :placeholder="_t('label_search_by_alarm_point_ID')"
                      class="custom-single-view-filter" @keydown.enter="filterCallback()"/>
                      <ExtendedCalendar
                          v-model:date="durationFilters"
                          orientation="left"
                      />
                  <InputText
                      v-model="alarmFilters.systemId"
                      :placeholder="_t('label_search_by_system_ID')" class="custom-single-view-filter"
                      @keydown.enter="filterCallback()"/>
                  </div>

                </template>
                  <template #body="{data}">
                    <div class="p-d-flex p-flex-column" style="flex-wrap: wrap;">
                      <div class="p-mt-2 p-mb-2">
                        <div class="p-d-flex p-flex-md-row">
                          <div class="p-mb-0 p-mr-2 p-ml-2" style="min-width: 200px;">
                            <p class="p-mb-0">
                              <strong
                                  v-tooltip.bottom="data.alarmPointName"
                                  class="truncate"
                                  style="display:inline-block; vertical-align: center; max-width: 15rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                    {{ data.alarmPointName }}
                              </strong>
                            </p>
                          </div>
                          <div class="p-mb-0 p-mr-2 p-ml-4">
                            <p class="p-ml-1">
                                <Badge
                                    :style="'background-color:' + associateColor(data.jobStatusColor)"
                                />
                                <span
                                    :style="'color:' + associateColor(data.jobStatusColor)"
                                    class="p-m-2"
                                >
                                  <strong> {{ _t(data.alarmJobStatus) }}</strong>
                                </span>
                                <Badge
                                    :style="'background-color:' + associateColor()"
                                />
                                <span
                                    :style="'color:' + associateColor()"
                                    class="p-m-2"
                                >
                                  <strong>{{ _t(data.alarmPointStatus) }}</strong>
                                </span>
                              </p>
                          </div>
                        </div>
                      </div>
                      <div class="p-mb-2">
                        <div class="p-d-flex p-flex-md-row">
                          <div class="p-mb-0 p-mr-2 p-ml-2" style="min-width: 200px;">
                            <span>
                              <Button v-if="data && data.icon && data.icon.name"
                                      :style="`font-size: 2rem; background-color: ${data.icon.color}; border-color: ${data.icon.color}; vertical-align: top; padding: .7rem; border-radius: 5px;`"
                                      class="p-mr-1">
                                <i :class="data.icon.name"></i>
                              </Button>
                              <Button v-else
                                      :style="`font-size: 2rem; background-color: #989898; border-color: #989898; vertical-align: top; padding: .7rem; border-radius: 5px;`"
                                      class="p-mr-1">
                                <i class="mdi mdi-alert-outline"></i>
                              </Button>
                              <div class="p-ml-3" style="display: inline-block;">
                                <p
                                    v-tooltip="data.alarmPointId"
                                    class="p-text-truncate p-m-0"
                                >
                                  {{ data.alarmPointId }}
                                </p>
                                <p v-tooltip="data.alarmPointSysId"
                                   class="p-text-truncate p-m-0"
                                >
                                  {{ data.alarmPointSysId ?? '' }}
                                </p>
                                <p v-tooltip="data.triggeredBy"
                                   class="p-text-truncate p-m-0"
                                >
                                  {{ data.triggeredBy }}
                                </p>
                              </div>
                            </span>
                          </div>
                          <div class="p-mb-2 p-mr-2 p-ml-4">
                            <p class="p-m-0">
                              <i class="mdi mdi-play-outline"
                                 style="font-size: 14px!important;"></i>
                              {{ data.alarmJobStartDate }}
                            </p>
                            <p class="p-m-0">
                              <i :class="data.alarmJobEndDate ?'mdi mdi-bookmark-outline' : 'mdi mdi-bookmark'"
                                 :style="data.alarmJobEndDate  ? 'color: black;font-size: 14px;' : 'color: red;font-size: 14px;'"></i>
                              {{ data.alarmJobEndDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </Column>
              </span>
        </DataTable>
      </div>
    </transition>

    <div class="style p-md-6 p-lg-6 p-sm-12">
      <history-singe-item ref="singleItemData" v-model:selected-job-id="selectedJobId"
                        :alarm-type="this.selectedTab < 7 ? this.selectedTab : 0" :hasTriggerProps="false">
      </history-singe-item>
    </div>
  </div>
  <Dialog v-model:visible="selectParticipantsDlg">
    <template #header>
      <h3>{{ _t('label_participants') }}</h3>
    </template>

    <p>{{ _t('primefaces.datatable.aria.FILTER_BY') }}: {{ _t('label_participants') }}</p>
    <ParticipantSelector v-model="participantsByPrefix" :allowParticipantGroups="false" scrollHeight="40vh"
                         style="width:30vw;"/>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="selectParticipantsDlg = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="selectParticipantsDlg = false"/>
    </template>
  </Dialog>
</template>

<script>

import {getAll, getHistory, getHistoryByAlarm} from "@/api/alarmTable";
import item from "@/views/alarm_table/alarms/item";
import {associateColor} from "@/service/helper/styleHelper";
import {mapActions, mapGetters} from "vuex";
import ExtendedCalendar from '@/components/ExtendedCalendar';
import SelectParticipant from "@/components/SelectParticipant";
import {getList} from "@/api/list";
import {getTableFilters, setTableFilters} from "@/api/tableFilters";
import router from "@/router";

export default {
  name: 'alarms',
  components: {
    'historySingeItem': item,
    ExtendedCalendar,
    SelectParticipant,
  },
  props: {
    groupFilter: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: null,
    },
    orgFilter: {
      type: Array,
      default: () => [],
    },
    startImmediately: {
      type: Boolean,
      default: true,
    },
    filtersPrefix: {
      type: String,
      default: '',
    },
    alarmTableValues: {
      type: Array,
      default: () => [],
    },
    additionalId: {
      type: Number,
      default: null
    }
  },
  emits: ['update:orgFilter', 'update:groupFilter', 'update:sortKey'],
  data() {
    return {
      loading: true,
      currentPage: 0,
      totalRecords: 0,
      selectedTab: this.activeTab || 0,
      alarmsIntervalId: null,
      alarms: [{
        alarmPointName: '',
        jobStatusColor: ''
      }],
      canBeWatched: false,
      participants: [],
      participantsFilter: [],
      participantsByPrefix: [],
      tableDataKey: 'alarmId',
      alarmTableRows: parseInt(localStorage.getItem('alarmTableRows') ?? 10),
      pageSize: [5, 10, 20, 50],
      alarmJobStatuses: ['NEW', 'CONFIG_PROBLEM', 'INACTIVE', 'FAILURE', 'FINISHED', 'INTERRUPTED',
        'CANCELED', 'NOT_ACCEPTED', 'NOT_ACKNOWLEDGED', 'PARTIAL_ACCEPTED', 'ACCEPTED',
        'PROCESSING', 'PARTIAL_ACKNOWLEDGED', 'ACKNOWLEDGED', 'SCHEDULED'
      ],
      alarmTableFilterKeys: [
        {
          label: 'Name',
          value: 'alarmPointName'
        },
        {
          label: 'Alarm Point Id',
          value: 'alarmPointId'
        },
        {
          label: 'System ID',
          value: 'alarmPointSysId'
        },
        {
          label: 'Triggered By',
          value: 'triggeredBy'
        },
        {
          label: 'Alarm Job Status',
          value: 'alarmJobStatus'
        },
      ],
      alarmFilterKey: '',
      alarmFilterValue: '',
      durationFilters: JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.durationFilters')) ?? {
        start: null,
        end: null,
        hours: 23,
        option: 'HOURS_DURATION',
      },
      alarmFilters: {
        alarmName: null,
        alarmPointId: "",
        statuses: [],
        systemId: null,
        triggeredBy: null,
      },

      selectedAlarmTableItem: null,
      selectedJobId: null,
      orgFilterIntervalId: null,
      search: {
        dateFrom: null,
        dateTo: null,
        orderByPriority: false,
        filterByParticipant: false,
      },
      hoursDuration: localStorage.getItem('af.' + this.filtersPrefix + '.durationFilters') ?
          JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.durationFilters')).hours :
          23,
      orgFilterLocal: null,
      selectParticipantsDlg: false,
      checkTableFilterIntervalId: null,
      externalHistoryalarmId: null,
      users: [],
      preparedTriggerBody: {
        "alarmText": "",
        "button": {},
        "instructionFiles": [],
        "tempAlarmPoint": null,
        "priority": 1
      },
      triggerAgainDlg: false,
      originalAlarmDialogText: '',
      computedAlarmDialogTextArray: [],
      replacedAlarmDialogText: '',
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.alarmsIntervalId))
    clearInterval(parseInt(localStorage.getItem('checkTableFilterIntervalId)')))
    this.canBeWatched = false
  },
  watch: {
    orderByPriority() {
      this.currentPage = 0
      this.loading = true
      this.refreshAlarms();
    },
    sortKey() {
      this.currentPage = 0
      this.loading = true
      this.refreshAlarms();
    },
    alarmTableRows(alarmTableRows) {
      localStorage.setItem('alarmTableRows', alarmTableRows)
      this.currentPage = 0
      if (this.canBeWatched === true) {
        this.loading = true
        this.refreshAlarms();
      }
    },
    durationFilters(value) {
      if (value.start === null && value.end === null) {
        this.durationFilters.hours = this.hoursDuration
        this.durationFilters.option = 'HOURS_DURATION'
      }
      this.currentPage = 0
      if (this.canBeWatched === true) {
        this.loading = true
        this.refreshAlarms();
      }
    },
    alarmFilterValue() {
      this.currentPage = 0
      if (this.canBeWatched === true) {
        this.loading = true
        this.refreshAlarms();
      }
    },
    alarmFilters() {
      this.currentPage = 0
      if (this.canBeWatched === true) {
        this.loading = true
        this.refreshAlarms();
      }
    },
    orgFilterLocal() {
      this.currentPage = 0
      if (this.canBeWatched === true) {
        this.loading = true
        this.refreshAlarms();
      }
    },
    orgFilter(value) {
      this.orgFilterLocal = value
    },
    hoursDuration(value) {
      if (value > 0) {
        this.loading = true
        this.initTab(this.selectedTab, this.alarmFilters.alarmPointId, value);
      } else {
        this.hoursDuration = JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.durationFilters')).hours ?? 23
      }
    },
    selectedJobId(value) {
      console.log(value)
    }
  },
  async mounted() {
    if (this.$route.query.alarmId) {
      this.selectedJobId = this.$route.query.alarmId
    }
    await this.getParticipants()
    await this.getUsers()
    if (localStorage.getItem('af.' + this.filtersPrefix + '.orgFilter') !== localStorage.getItem('multiOrgIds')) {
      localStorage.setItem('af.' + this.filtersPrefix + '.orgFilter', localStorage.getItem('multiOrgIds'))
    }
  },
  created() {
    this.loadFilters()
    this.refreshAlarms();
    this.checkAlarmFilters()
  },
  computed: {
    ...mapGetters({
      selectedParticipants: 'participant/getSelectedParticipants',
    }),
    tableHeight(){
      if(this.groupFilter) {
        return 'calc(100vh - 415px)'
      } else {
        return 'calc(100vh - 273px)'
      }
    },
  },
  methods: {
    ...mapActions({
      setSelectedParticipants: 'participant/setSelectedParticipants',
    }),
    replaceAlarmDialogText(text) {
      this.replacedAlarmDialogText = text
    },
    async handleSaveFilters() {
      const storageParticipantsFilter = JSON.parse(localStorage.getItem('af.' + this.filtersPrefix + '.participantsFilter'))
      const filters = {
        durationFilters: this.durationFilters,
        sortKey: this.sortKey,
        alarmFilterValue: this.alarmFilterValue,
        alarmTableRows: this.alarmTableRows,
        orgFilter: this.orgFilter,
        currentPage: this.currentPage,
        alarmFilters: this.alarmFilters,
        participantsFilter: storageParticipantsFilter,
        multiOrgData: JSON.parse(localStorage.getItem('multiOrgData'))
      }
      await setTableFilters(JSON.stringify(filters))
    },
    async clearAllFilters() {
      this.alarmFilters = {
        alarmName: null,
        alarmPointId: "",
        statuses: [],
        systemId: null,
        triggeredBy: null,
      }
      this.currentPage = 0
      this.durationFilters = {
        start: null,
        end: null,
        hours: 23,
        option: 'HOURS_DURATION',
      }
      this.hoursDuration = 23
      this.alarmFilterValue = ''
      const filters = {
        durationFilters: this.durationFilters,
        sortKey: this.sortKey,
        alarmFilterValue: this.alarmFilterValue,
        alarmTableRows: this.alarmTableRows,
        orgFilter: this.orgFilter,
        currentPage: this.currentPage,
        alarmFilters: this.alarmFilters,
        multiOrgData: JSON.parse(localStorage.getItem('multiOrgData'))
      }
      await setTableFilters(JSON.stringify(filters))
    },
    setParticipant() {
      this.participantsFilter = this.selectedParticipants
      this.refreshAlarms();
      localStorage.setItem('af.' + this.filtersPrefix + '.participantsFilter', JSON.stringify(this.selectedParticipants))
    },
    getParticipants() {
      getList('PARTICIPANT', 0, '', 1000).then((response) => {
        this.participants = response.data.list.filter(function (item) {
          return item.login;
        })
        this.loading = false
      })
    },
    getUsers() {
      getList('USER', 0, '', 1000, 1, true, 'login').then((response) => {
        this.users = response.data.list.map((el) => ({value: el.login, label: `${el.login}`}))
        this.loading = false
      })
    },
    parseJobIdByType(data) {
      if (this.$route.query.alarmId) {
        return this.$route.query.alarmId
      }

      switch (this.selectedTab) {
        case 0:
          return data.alarmJobId
        case 1:
          return data.historyId
        case 2:
          return data.historyId
        case 30:
          router.push(`/alarm-table?tab=2&alarmId=${data.historyId}`)
          return null
        default:
          return null
      }
    },
    triggerGetAlarm(data) {
      this.selectedJobId = this.parseJobIdByType(data)
    },
    refreshAlarms() {
      if (this.startImmediately === true) {
        switch (parseInt(this.selectedTab)) {
          case 0:
            this.tableDataKey = 'alarmId'
            this.$emit('update:groupFilter', false);
            this.runWithInterval('getAllAlarms', 5000)
            break;
          case 1:
            this.tableDataKey = 'historyId'
            this.$emit('update:groupFilter', false);
            this.runWithInterval('getMyAlarms', 5000)
            break;
          case 2:
            this.tableDataKey = 'historyId'
            this.runWithInterval('getHistoryAlarms', 5000)
            break;
          case 30:
            this.tableDataKey = 'historyId'
            this.getHistoryByAlarmId(this.externalHistoryalarmId, null, {hours: '999999999', option: 'HOURS_DURATION'})
            break;
        }
      }
      this.canBeWatched = true
    },
    runWithInterval(method, interval = null) {
      if (this.alarmsIntervalId === null && interval !== null) {
        this[method]()
        this.alarmsIntervalId =
            setInterval(() => {
              this[method]()
            }, interval)
      } else {
        this[method]()
      }
    },
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    selectTablePage(item) {
      this.loading = true
      this.currentPage = item.page
      this.refreshAlarms();
    },
    onFilter() {
      this.loading = true
      this.refreshAlarms();
    },
    clearFilter() {
      this.alarmFilterValue = ''
    },
    getMyAlarms() {
      const storagePartsFilter = localStorage.getItem('af.my.participantsFilter')
      getHistory(
          this.currentPage,
          this.sortKey,
          this.alarmFilterValue,
          this.alarmTableRows,
          this.alarmFilters,
          this.orgFilter,
          this.durationFilters,
          this.orderByPriority,
          JSON.parse(storagePartsFilter)
      ).then((response) => {
        this.alarms = response.data.list.map((element) => {
          let container = element;
          container.icon = container.icon ? JSON.parse(container.icon) : ''
          return container;
        })
        this.totalRecords = response.data.count
        this.saveFilters()
        this.loading = false
      })
    },
    getHistoryAlarms(customAlarmFilters = null, customDurationFilters = null) {
      const storagePartsFilter = localStorage.getItem('af.history.participantsFilter')
      this.durationFilters.hours = this.hoursDuration;
      getHistory(
          this.currentPage,
          this.sortKey,
          this.alarmFilterValue,
          this.alarmTableRows,
          customAlarmFilters ?? this.alarmFilters,
          this.orgFilter,
          customDurationFilters ?? this.durationFilters,
          this.orderByPriority,
          JSON.parse(storagePartsFilter)
      ).then((response) => {
        this.alarms = response.data.list.map((element) => {
          let container = element;
          container.icon = container.icon ? JSON.parse(container.icon) : ''
          return container;
        })
        if (!this.alarms.length) {
          this.selectedJobId = null;
        }
        this.totalRecords = response.data.count
        this.saveFilters()
        this.loading = false
      })
    },
    getHistoryByAlarmId(alarmId, customAlarmFilters = null, customDurationFilters = null) {
      const storagePartsFilter = localStorage.getItem('af.my.participantsFilter')
      this.externalHistoryalarmId = alarmId
      this.durationFilters.hours = this.hoursDuration;
      if (alarmId || this.additionalId) {
        getHistoryByAlarm(
            alarmId || this.additionalId,
            this.currentPage,
            this.alarmFilterKey.value,
            this.alarmFilterValue,
            this.alarmTableRows,
            customAlarmFilters ?? this.alarmFilters,
            this.orgFilter,
            customDurationFilters ?? this.durationFilters,
            JSON.parse(storagePartsFilter)
        ).then((response) => {
          this.alarms = response.data.list.map((element) => {
            let container = element;
            container.icon = container.icon ? JSON.parse(container.icon) : ''
            return container;
          })
          if (!this.alarms.length) {
            this.selectedJobId = null;
          }
          this.totalRecords = response.data.count
          this.saveFilters()
          this.loading = false
        })
      }
    },
    downloadFile(response, filename) {
      var newBlob = new Blob([response.data])
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data), 100
      })
    },
    getAllAlarms() {
      console.log(this.participantsFilter)
      getAll(this.currentPage, this.sortKey, this.alarmFilterValue, this.alarmTableRows, this.alarmFilters, this.orgFilter, this.durationFilters, this.orderByPriority, this.participantsFilter)
          .then((response) => {
            this.alarms = response.data.list.map((element) => {
              let container = element;
              container.icon = container.icon ? JSON.parse(container.icon) : ''
              return container;
            })
            this.totalRecords = response.data.count
            this.saveFilters()
            this.loading = false
          })
    },
    associateColor,
    clearComponent(dropSelectedItem = false) {
      if (dropSelectedItem) {
        this.selectedAlarmTableItem = null;
        this.selectedJobId = null;
        this.alarmFilters.alarmPointId = "";
        this.alarmFilters.statuses = [];
        this.alarmFilters.systemId = null;
        this.durationFilters.start = null;
        this.durationFilters.end = null;
        this.currentPage = 0
      }
      clearInterval(parseInt(this.alarmsIntervalId));
      this.alarmsIntervalId = null;
    },
    initTab(id, alarmPointId = null, hoursDuration = 10000) {
      this.selectedTab = id
      if (this.durationFilters.start === null && this.durationFilters.end === null) {
        this.hoursDuration = hoursDuration
        this.durationFilters.hours = hoursDuration
        this.durationFilters.option = 'HOURS_DURATION'
      } else {
        delete this.durationFilters.hours
        this.durationFilters.option = 'RANGE'
      }
      if (alarmPointId !== null) {
        this.alarmFilters.alarmPointId = alarmPointId
      }
      this.saveFilters()
      if (this.canBeWatched === true) {
        this.refreshAlarms();
        this.loading = false;
      }
    },
    checkAlarmFilters() {
      this.checkTableFilterIntervalId = setInterval(() => {
        if (JSON.stringify(this.alarmFilters) != localStorage.getItem('af.' + this.filtersPrefix + '.alarmFilters')) {
          localStorage.setItem('af.' + this.filtersPrefix + '.alarmFilters', JSON.stringify(this.alarmFilters));
        }
      }, 1500);
    },
    loadFilters() {
      if (this.filtersPrefix === 'my') {
        getTableFilters().then((res) => {
          if (res.data) {
            Object.keys(res.data).forEach((el) => {
              if (this.selectedTab === 1 && el !== 'orgFilter' && el !== 'sortKey' && el !== 'currentPage' && el !== 'participantsFilter') {
                this[el] = res.data[el]
              }

              if (el === 'multiOrgData') {
                console.log('set')
                localStorage.setItem('multiOrgData', JSON.stringify(res.data[el]))
                this.refreshAlarms()
              }

              if (el === 'orgFilter') {
                this.orgFilterLocal = res.data[el]
                this.$emit('update:orgFilter', res.data[el])
              }

              if (el === 'sortKey') {
                this.$emit('update:sortKey', res.data[el])
                localStorage.setItem('af.my.' + el, JSON.stringify(res.data[el]))
              }

              if (el === 'participantsFilter') {
                this.participantsByPrefix = res.data[el]
                this[el] = res.data[el]
                localStorage.setItem('af.my.' + el, JSON.stringify(res.data[el]))
              }
            })
          }
        })
      }

      let filters = localStorage.getItem('af.' + this.filtersPrefix + '.currentPage')
      if (filters) {
        this.currentPage = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.sortKey')
      if (filters) {
        this.sortKey = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.alarmFilterKey')
      if (filters) {
        this.alarmFilterKey = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.alarmFilterValue')
      if (filters) {
        this.alarmFilterValue = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.alarmTableRows')
      if (filters) {
        this.alarmTableRows = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.alarmFilters')
      if (filters) {
        this.alarmFilters = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.orgFilter')
      if (filters) {
        this.orgFilterLocal = filters
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.durationFilters')
      if (filters) {
        this.durationFilters = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.orderByPriority')
      if (filters) {
        this.orderByPriority = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.' + this.filtersPrefix + '.participantsFilter')
      if (filters) {
        this.participantsFilter = JSON.parse(filters)
      }
    },
    saveFilters() {
      if (this.filtersPrefix === 'my') {
        this.handleSaveFilters()
      }
      if (this.currentPage) {
        localStorage.setItem('af.' + this.filtersPrefix + '.currentPage', JSON.stringify(this.currentPage))
      }

      if (this.sortKey) {
        localStorage.setItem('af.' + this.filtersPrefix + '.sortKey', JSON.stringify(this.sortKey))
      }

      if (this.alarmFilterKey) {
        localStorage.setItem('af.' + this.filtersPrefix + '.alarmFilterKey', JSON.stringify(this.alarmFilterKey))
      }

      if (JSON.stringify(this.alarmFilterValue)) {
        localStorage.setItem('af.' + this.filtersPrefix + '.alarmFilterValue', JSON.stringify(this.alarmFilterValue))
      }

      if (this.alarmTableRows) {
        localStorage.setItem('af.' + this.filtersPrefix + '.alarmTableRows', JSON.stringify(this.alarmTableRows))
      }

      if (this.alarmFilters) {
        localStorage.setItem('af.' + this.filtersPrefix + '.alarmFilters', JSON.stringify(this.alarmFilters))
      }

      if (this.orgFilterLocal) {
        localStorage.setItem('af.' + this.filtersPrefix + '.orgFilter', JSON.stringify(this.orgFilterLocal))
      }

      if (this.durationFilters) {
        localStorage.setItem('af.' + this.filtersPrefix + '.durationFilters', JSON.stringify(this.durationFilters))
      }

      if (this.orderByPriority) {
        localStorage.setItem('af.' + this.filtersPrefix + '.orderByPriority', JSON.stringify(this.orderByPriority))
      }
    },
    initSelectParticipantsDlg() {
      this.$refs.pf.hide()
      this.selectParticipantsDlg = true
    },
    handleCheckRow(name) {
      const foundedValue = this.alarmTableValues.find(({value}) => value === name)
      return !!foundedValue?.checked?.length || this.selectedTab == 30;
    },
  },
}
</script>

<style lang="scss">
.participant-list {
  min-height: 14px;
  padding: 7px;

  .p-chip {
    font-size: 12px;
    margin-right: 1em;
    margin-bottom: .5em;
    padding: 0.2em 1em;
  }
}

</style>
<style lang="scss" scoped>
.participant-list {
  max-width: 40em;

  .p-chip {
    font-size: 12px;
    margin-right: 2em;
  }
}

.nowrap {
  white-space: nowrap;
}

#extendedCalendar {
  display: inline-block;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  //display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  //.p-datatable-wrapper {
  //  max-width: 79vw;
  //}

  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 2px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid white;

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 5px;
  }

}


.customer {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customer td, .customers th {
  border: none;
  padding: 5px;
}

.customer tr:nth-child(even) {
  //background-color: #f2f2f2;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-th th {
  display: none;
}

p-tabview-nav-link {
  color: #82B541;
  border-color: #82B541;
}

.p-highlight {
  background-color: #82B541 !important;
}

.custom-single-view-filter {
  width: 30% !important;
  margin: 5px !important;
}

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.filter-label {
  display: inline-block;
  min-width: 100px;
  text-align: right;
}

.filter-input {
  width: 150px;
}

</style>
